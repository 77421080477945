<template>
  <form @submit.prevent="saveLabor">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="headerData.title"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
        />
        <UIButton
          type="button"
          v-show="editable"
          text="Удалить"
          leftIcon="bi-trash"
          @click="deleteLabor"
          float
        />
      </div>
    </div>
    <div class="row gap-3" v-if="data">
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkIdentifiedObject.IdentifiedObject.name"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Описание:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkIdentifiedObject.IdentifiedObject.description"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Тариф:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.enproRate.value"
                type="number"
                :editable="editable"
                required
              />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Код:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.code"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Описание:</dt>
            <dl class="col">
              <EditableSelect
                :value="lodash.get(data, 'status.id')"
                @input="(e) => lodash.set(data, 'status.id', e)"
                :options="status"
                :editable="editable"
                required
                label="Статус"
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import {
  API_labor_view,
  API_labor_delete,
  API_labor_update,
} from "@/services/references/labor";
import { API_StatusList } from "@/services/api";

export default {
  name: "cULaborCodeViewEdit",
  components: { UIButton, EditableInput, EditableSelect },
  data() {
    return {
      /**
       * @type LaborObject
       */
      data: null,
      status: [],
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return true;
        default:
          return false;
      }
    },
    headerData: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return {
            title: "Редактирование норматива",
          };
        default:
          return {
            title: "Просмотр норматива",
          };
      }
    },
  },
  mounted() {
    //Загрузка возможных статусов
    API_StatusList().then((res) => {
      this.status = res;
    });
    API_labor_view(parseInt(this.$route.params.laborId)).then((res) => {
      this.data = res;
    });
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/references/labor-code/${this.$route.params.laborId}/edit`,
      });
    },
    deleteLabor: function () {
      API_labor_delete(parseInt(this.$route.params.laborId)).finally(() => {
        this.$router.push({
          path: "/references/labor-code/",
        });
      });
    },
    saveLabor: function () {
      API_labor_update(parseInt(this.$route.params.laborId), this.data).then(
        () => {
          this.$router.push({
            path: `/references/labor-code/${this.$route.params.laborId}`,
          });
        }
      );
    },
  },
};
</script>

<style scoped></style>
